.footer {
    background-color: white;
    color: black;
    padding-top: 40px;
    border-top: 1px solid black;
  }
  
  .footerContainer {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 10px;
  }
  
  .footerLeft h3 {
    margin: 0;
    font-size: 24px;
  }
  
  .footerLeft p {
    margin-top: 10px;
    font-size: 14px;
    color: #b3b3b3;
  }
  
  .footerCenter ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footerCenter ul li {
    margin: 10px 0;
  }
  
  .footerCenter ul li a {
    color: black;
    text-decoration: none;
  }
  
  .footerCenter ul li a:hover {
    text-decoration: underline;
  }
  
  .footerRight p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .footerRight {
      display: none; /* Hide on screens smaller than 768px */
    }
    .footerLeft{
      padding-left: 10px;
      padding-right: 20px;
    }
  }
  
  .socialIcons a {
    color: black;
    font-size: 20px;
    margin-right: 15px;
    text-decoration: none;
  }
  
  .socialIcons a:hover {
    color: #3498db;
  }
  
  .footerBottom {
    text-align: center;
    padding: 20px 0;
    background-color: black;
  }
  
  .footerBottom p {
    margin: 0;
    font-size: 14px;
    color: #b3b3b3;
  }
  
  