.shopContainer {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .title {
    text-align: left;
    font-size: 2.5rem;
  }

  .subtitle {
    margin-top: 0px;
    text-align: left;
    font-size: 2.5rem;
    font-size: medium;
    color: grey;
    margin-bottom: 40px;
  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .card {
    flex-basis: calc(25% - 20px); /* Each card takes 25% of the width with spacing */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
  }
  
  .image {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .info {
    padding: 20px;
    text-align: center;
  }
  
  h3 {
    margin: 10px 0;
    font-size: 1.5rem;
  }
  
  p {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #555;
  }
  
  .addToCartButton {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .addToCartButton:hover {
    background-color: #2980b9;
  }
  
  @media (max-width: 768px) {
    .card {
      flex-basis: 100%; /* Each card takes 100% of the width in mobile */
    }
  }
  