/* Checkout Page Container */
.checkoutPageContainer {
    max-width: 1100px;
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
  }
  
  .checkoutContent {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  /* Checkout Form Section */
  .checkoutFormContainer {
    flex: 2;
  }
  
  .checkoutHeading {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .checkoutForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup label {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .formGroup input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .addressRow {
    display: flex;
    gap: 15px;
  }
  
  .addressRow .formGroup {
    flex: 1;
  }
  
  .cardDetails {
    display: flex;
    gap: 15px;
  }
  
  .cardDetails .formGroup {
    flex: 1;
  }
  
  /* Checkout Button */
  .checkoutButton {
    background-color: #333;
    color: white;
    padding: 12px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkoutButton:hover {
    background-color: #555;
  }
  
  /* Cart Summary Section */
  .cartSummaryContainer {
    flex: 1;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }
  
  .summaryHeading {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .cartItems {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .cartItem {
    display: flex;
    justify-content: space-between;
  }
  
  .itemName {
    font-size: 1.2rem;
  }
  
  .itemQuantity {
    font-size: 1.1rem;
    color: #555;
  }
  
  .totalAmount {
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: bold;
  }
  
  /* Mobile Responsiveness */
  @media screen and (max-width: 768px) {
    .checkoutContent {
      flex-direction: column;
    }
  
    .addressRow, .cardDetails {
      flex-direction: column;
    }
  }
  