/* Global styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
}

.App {
    text-align: center;
}

.scrolling-text {
    background-color: black;
    color: white;
    width: 100%;
    text-align: end;
    padding: 0 0;
    font-size: 16px;
    position: relative;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
}

.scrolling-text.hidden {
    transform: translateY(-100%);
    width: 100%;
}
/* .scrolling-text-info {
    display: inline-block;
    color: white;
    align-items: start;
    font-size: 16px;
    white-space: nowrap;
    width: 100%;
} */


.scrolling-text-info {
    display: inline-block;
    color: white;
    font-size: 16px;
    white-space: nowrap; 
    animation: scroll-left 5s linear infinite;
    overflow: hidden;
    width: 100%;   
  }

  /* Keyframes to define the horizontal scroll behavior */
  @keyframes scroll-left {
    0% {
      transform: translateX(0%);   /* Start the text just outside the right side */
    }
    50% {
      transform: translateX(-25%);  /* Move the text fully outside the left side */
    }
    100%{
        transform: translateX(0%);
    }
}

/* Navbar */
.navbar {
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 1;
    transition: top 0.3s ease-in-out;
    border-bottom: 1px solid black;
}

.navbar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100vw; 
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
}

/* Centered text */
.nav-title {
    font-size: 32px;
    font-weight: bold;
    color: black;
}

/* Icon */
.nav-cart-icon {
    width: 30px;
    height: 4s0px;
    cursor: pointer;
}

.nav-sub-container{
    display: flex;
    gap: 40px;
}

.nav-sub-link{
    font-family:'Courier New', Courier, monospace;
    text-decoration: none;
    color: grey;
    font-size: 20px;
}

/* Main content */
.content {
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .nav-sub-container {
      gap: 15px;
    }
    .nav-sub-link{
        font-size: 15px;
    }
}