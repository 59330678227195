/* Container and Heading */
.cartPageContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    min-height: 75vh;
  }
  
  .cartHeading {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
  }
  
  /* Content Layout */
  .cartContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
  
  .cartItemsSection {
    flex: 2;
  }
  
  /* Cart Items */
  .cartItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .itemImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5px;
  }
  
  .itemDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .itemName {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .itemPrice,
  .itemQuantity,
  .itemSize {
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: #555;
  }
  
  .removeButton {
    background-color: #ff6b6b;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .removeButton:hover {
    background-color: #ff4c4c;
  }
  
  .emptyCartMessage {
    font-size: 1.5rem;
    color: #777;
    text-align: center;
    margin-top: 40px;
  }
  
  /* Cart Summary */
  .cartSummarySection {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .summaryHeading {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .summaryContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .subtotal,
  .shipping,
  .total {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    color: #333;
  }
  
  .total {
    font-weight: bold;
    font-size: 1.4rem;
  }
  
  .checkoutButton {
    background-color: #333;
    color: #fff;
    padding: 12px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .checkoutButton:hover {
    background-color: #555;
  }
  
  /* Mobile Responsiveness */
  @media screen and (max-width: 768px) {
    .cartContent {
      flex-direction: column;
    }
  
    .cartItem {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .itemDetails {
      margin-top: 10px;
    }
  
    .itemImage {
      width: 80px;
      height: 80px;
    }
  
    .checkoutButton {
      width: 100%;
      text-align: center;
    }
  
    .cartSummarySection {
      margin-top: 20px;
    }
  }
  