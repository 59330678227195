.videoContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlayContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  text-align: center;
}

.overlayContent h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.overlayContent p {
  font-size: 20px;
  margin-bottom: 20px;
}

.ctaButton {
  padding: 10px 20px;
  font-size: 18px;
  font-family: 'Bebas Neue', sans-serif;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #2980b9;
}
