.productDetailContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    height: 100vh;
  }
  
  .productImage {
    width: 300px;
    height: 400px;
    object-fit: cover;
    margin-right: 40px;
  }
  
  .productInfo {
    max-width: 500px;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .productPrice {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .productDescription {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .addToCartButton {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .addToCartButton:hover {
    background-color: #2980b9;
  }
  